import { endpoints } from 'src/environments/endpoints';
import { HttpClient, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { adminEndpoints } from 'src/environments/adminEndpoints';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UploadVideoService {

  constructor(private http: HttpClient) { }

  upload(file: File, form: object): Observable<HttpEvent<any>> {

    const formData: FormData = new FormData();

    formData.append('file', file);

    Object.keys(form).forEach(key => {
      formData.append(key, form[key]);
    });

    const req = new HttpRequest(
      'POST',
      environment.api.host + adminEndpoints.adminUploadVideo,
      formData,
      {
        reportProgress: true,
        // responseType: 'json'
      }
    );

    return this.http.request(req);
  }

  incrementNbViews(id: number): Observable<any> {
    return this.http.post(environment.api.host + endpoints.incrementVideo, {id}).pipe(
      map(
        (res: any) => res
      ),
      catchError(this.handleError)
    );
  }

  remove(id: number): Observable<any> {
    return this.http.post(environment.api.host + adminEndpoints.adminRemoveVideo, {id}).pipe(
      map(
        (res: any) => res
      ),
      catchError(this.handleError)
    );
  }

  getVideo(key: string): Observable<any> {
    return this.http.get(environment.api.host + adminEndpoints.adminVideo + '/' + key);
  }

    protected handleError(error: any): Promise<any> {
    if (error.status === 401) {
      window.location.reload();
    }

    return Promise.reject(error?.error?.message || error?.message || error);
  }
}
