export const adminEndpoints = {
    updateAdmin: '/admin/privileges',
    adminUpload: '/admin/upload',
    adminUploadVideo: '/admin/upload_video',
    adminFile: '/admin/file',
    adminVideo: '/admin/video',
    adminRemoveFile: '/admin/remove/file',
    adminRemoveVideo: '/admin/remove/video',
    users: '/users',
    nonDpUsers: '/nonDpUsers',
    createUser: '/users',
    testimonials: '/testimonials'
};
